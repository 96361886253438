<template>
  <div class="toCellInventory">
    <div class="publicSearch">
      <div class="searchBar" :style="{ height: isMoreSearch ? 'auto' : '' }">
        <el-button-group>
          <el-button @click="getInventoryBalanceData" size="mini" icon="el-icon-search">查询</el-button>
          <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">重置</el-button>
        </el-button-group>
        <div>
          <span>关键字搜索</span>
          <el-input v-model="searchData.keyWords" size="mini" placeholder="货位/托盘号/LPN号" clearable></el-input>
        </div>
        <div>
          <span>产品名称</span>
          <el-input v-model="searchData.itemName" size="mini" placeholder="产品名称" clearable></el-input>
        </div>
        <div>
          <span>产品编码</span>
          <el-input v-model="searchData.itemCode" size="mini" placeholder="产品编码" clearable></el-input>
        </div>
        <div>
          <span>货主</span>
          <el-select v-model="searchData.organizationId" size="mini" filterable placeholder="请选择货主" clearable>
            <el-option
              v-for="item in organizations"
              :key="item.organizationId"
              :label="'(' + item.organizationId + ')' + item.organizationName"
              :value="item.organizationId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <span class="moreSearchBtn"
            :class="isMoreSearch?'el-icon-caret-top':'el-icon-caret-bottom'"
            @click="isMoreSearch = !isMoreSearch"></span> -->
    </div>

    <div class="headerBtns">
      <el-button
        size="small"
        type="primary"
        :disabled="multipleSelection.length != 1"
        @click="
          dialogGroupBox = true;
          groupBoxData = multipleSelection[0];
        "
        >组盘</el-button
      >
    </div>

    <el-dialog title="组盘" :visible.sync="dialogGroupBox" :before-close="handleClose" width="900px" style="margin-top: -10vh;">
      <el-descriptions class="margin-top" title=" " :column="3" :size="size" border>
        <el-descriptions-item :label="$t(`m.common.product_name`)">{{ groupBoxData.itemName }}</el-descriptions-item>
        <el-descriptions-item label="产品编码">{{ groupBoxData.itemCode }}</el-descriptions-item>
        <el-descriptions-item label="货主">{{ groupBoxData.organizationName }}</el-descriptions-item>
        <el-descriptions-item label="批次">{{ groupBoxData.batchName }}</el-descriptions-item>
        <el-descriptions-item label="单位">{{ groupBoxData.packDescribe }}</el-descriptions-item>
        <el-descriptions-item label="库存数量">{{
          Math.round((groupBoxData.quantity / groupBoxData.transRatio) * 10000) / 10000
        }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top" title=" " :column="2" :size="size" border>
        <el-descriptions-item label="托盘号"
          ><el-input v-model="groupBoxData.toBoxCode" placeholder="请输入托盘号"></el-input
        ></el-descriptions-item>
        <el-descriptions-item label="组盘数量"
          ><el-input v-model="groupBoxData.toQuantity" placeholder="请输入组盘数量"></el-input
        ></el-descriptions-item>
      </el-descriptions>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogGroupBox = false">取 消</el-button>
        <el-button type="primary" @click="groupBox(groupBoxData)">确 定</el-button>
      </div>
    </el-dialog>

    <div class="tableBox">
      <el-table
        :header-cell-style="$parent.tableHeaderColor"
        highlight-current-row
        stripe
        border
        v-loading="loading.masterTable"
        :data="inventoryBalances"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column fixed="left" type="selection" min-width="50"> </el-table-column>
        <el-table-column fixed="left" show-overflow-tooltip prop="inventoryId" label="库存ID" min-width="80"> </el-table-column>
        <el-table-column fixed="left" prop="itemName" :label="$t(`m.common.product_name`)" min-width="260">
          <template slot-scope="scope">
            <el-row :gutter="20">
              <el-col :span="8">
                <div style="display: inline-block;margin-left:5px;  width:60px;height:60px">
                  <el-image style="width: 60px; height: 60px" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]"> </el-image>
                  <!-- <img v-bind:src="scope.row.img_url"  style="width:80px;height:86px"> -->
                </div>
              </el-col>
              <el-col :span="16">
                <div @click="$parent.showPublicModal(scope.row.itemCode, 'item')" style="display: inline-block;margin-left:5px;  ">
                  <span class="textBtn" style="color:red">{{ scope.row.itemCode }}</span>

                  <!-- <span class="textBtn" >{{scope.row.itemName}}</span> -->
                  <br />
                  <span>{{ scope.row.spec }}</span>
                </div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="organizationName" label="货主" min-width="130">
          <template slot-scope="scope">
            <span>({{ scope.row.organizationId }}){{ scope.row.organizationName }}</span>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="batchName" label="批次" min-width="130"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="quantity" label="数量" min-width="140"> </el-table-column>

        <el-table-column show-overflow-tooltip prop="modifyTime" label="时间" min-width="140"> </el-table-column>

        <!-- <el-table-column
           
          show-overflow-tooltip
          prop="allotQuantity"
          label="分配数量"
           min-width="140">
        <template slot-scope="scope">
            <span style="color:black;font-weight:900;">{{  scope.row.allotQuantity   }} </span>
            <span v-if="scope.row.allotQuantity>0" style="font-size:20% ;color:red ">爆</span>
          </template>  
        
            
        </el-table-column> -->

        <el-table-column show-overflow-tooltip prop="cellCode" label="货位" min-width="150"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="boxCode" label="托盘号" min-width="150"> </el-table-column>
      </el-table>
      <el-pagination
        class="pagination-bottom"
        style="text-align: right;"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="searchData.pageNum"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="searchData.pageSize"
        layout="sizes,total, prev, pager, next"
        :total="searchData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'ToCellInventory',
    data() {
      let validateQuantity = (rule, value, callback) => {
        if (value <= 0) {
          callback(new Error('数量不得为零'));
          callback();
        } else if (value > 0) {
          callback();
        } else {
          callback(new Error('请输入正确格式'));
        }
      };
      let validateCellCode = (rule, value, callback) => {
        this.validator(rule, value, callback, 'cell_info', 'cell_code', this.form.toCellCode, 0);
      };
      return {
        loading: {
          masterTable: true,
        },

        isMoreSearch: false,
        isMoreSearch3: false,

        // 组盘数据
        dialogGroupBox: false,
        groupBoxData: {},

        searchData: {
          pageNum: 1,
          pageSize: 15,
          total: null,
          keyWords: '',
          wareId: PF.getLocal('wareId', 'number'),
          organizationId: null,
          cellUseType: 0,
          itemName: '',
          itemCode: '',
        },
        searchSelectCell: {
          pageNum: 1,
          pageSize: 5,
          total: null,
          keyWords: '',
          wareId: '',
          areaId: '',
          cellUseTypes: { 0: 2 },
        },

        inventoryBalances: [],
        organizations: [],
        areas: [],
        cells: [],
        multipleSelection: [],

        serialNo: false,
        serialNo1: [],
        scanner: '',
        select: true,

        isFindUpperCell: false,
        dialogNewVisible: false,
        innerVisibleCell: false,

        formLabelWidth: '120px',

        form: {
          row: {},
          findUpperCell: '',
          toCellCode: '',
          toBoxCode: '',
          toQuantity: null,
          serialNos: [],
        },
        rules: {
          toCellCode: [{ required: true, message: '请选择目标货位', trigger: 'blur' }],
          toBoxCode: [{ required: true, message: '请选择目标托盘号', trigger: 'blur' }],
          toQuantity: [
            { required: true, message: '请输入数量', trigger: 'blur' },
            { validator: validateQuantity, trigger: 'blur' },
          ],
        },
      };
    },
    computed: {
      ...mapState(['cellUseTypes', 'cellAbcLevels', 'cellPickTypes']),
    },
    methods: {
      handleSizeChange(val) {
        this.searchData.pageSize = val;
        this.getInventoryBalanceData();
      },
      addSerialNo() {
        if (this.select) {
          this.changeSerialNos();
          let pFocus = document.getElementById('scanner');
          pFocus.focus();
        }
      },
      affirmSerialNo() {
        this.changeSerialNos();
        let pFocus = document.getElementById('scanner');
        pFocus.focus();
      },
      changeSerialNos() {
        for (let i = 0; i < this.serialNo1.length; i++) {
          if (this.scanner === this.serialNo1[i].serialNo) {
            if (this.form.serialNos.length > 0) {
              for (let j = 0; j < this.form.serialNos.length; j++) {
                if (this.scanner === this.form.serialNos[j]) {
                  IOT.tips('当前序列号已录入!', 'error', 1000, () => {
                    this.scanner = '';
                    let pFocus = document.getElementById('scanner');
                    pFocus.focus();
                  });
                  return false;
                }
              }
            }
            this.form.serialNos.push(this.serialNo1[i].serialNo);
            this.scanner = '';
            let pFocus = document.getElementById('scanner');
            pFocus.focus();
            document.getElementById('serialNoId' + this.serialNo1[i].serialNoId + '').style.color = 'red';
            break;
          } else if (i === this.serialNo1.length - 1) {
            IOT.tips('当前货物无此序列号!', 'error', 1000, () => {
              this.scanner = '';
              let pFocus = document.getElementById('scanner');
              pFocus.focus();
            });
            return false;
          }
        }
      },
      handleCloseSerialNo() {
        this.serialNo = false;
        this.scanner = '';
      },
      showSerialNo() {
        this.serialNo = true;
        let pFocus = document.getElementById('scanner');
        pFocus.focus();
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.getInventoryBalanceData();
      },
      handleCurrentChange3(val) {
        this.searchSelectCell.pageNum = val;
        this.getCellData();
      },
      clearSearchData() {
        this.searchData.keyWords = '';
        this.searchData.organizationId = null;
        this.searchData.itemName = null;
        this.searchData.itemCode = null;
      },
      clearSearchSelectCell() {
        this.searchSelectCell.keyWords = '';
      },
      changePackDetailId(index) {
        let detail = PF.JSON(this.inventoryBalances[index]);
        for (let i = 0; i < detail.packDetails.length; i++) {
          if (detail.packDetails[i].packDetailId1 === detail.packDetailId) {
            this.inventoryBalances[index].packDescribe = detail.packDetails[i].packDescribe1;
            this.inventoryBalances[index].transRatio = detail.packDetails[i].transRatio1;
          }
        }
      },
      getInventoryBalanceData() {
        let that = this;
        that.loading.masterTable = true;
        let searchData = PF.JSON(this.searchData);
        if (!PF.isNull(searchData.itemCode)) {
          delete searchData.itemCode;
        }
        if (!PF.isNull(searchData.itemName)) {
          delete searchData.itemName;
        }
        IOT.getServerData('/inventorys/findToBox', 'get', searchData, (ret) => {
          that.loading.masterTable = false;
          if (ret.code === 200) {
            this.inventoryBalances = ret.rows;
            this.searchData.total = ret.total;
            this.searchData.pageNum = ret.pageNumber;
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
      getCellData() {
        IOT.getServerData('/cell/infos/list', 'get', this.searchSelectCell, (ret) => {
          if (ret.code === 200) {
            this.cells = ret.rows;
            this.searchSelectCell.total = ret.total;
            this.searchSelectCell.pageNum = ret.pageNumber;
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
      getAreaData() {
        IOT.getServerData('/area/infos/list', 'get', { wareId: this.searchSelectCell.wareId }, (ret) => {
          if (ret.code === 200) {
            this.areas = ret.rows;
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
      showNewModal(row) {
        this.findUpperCell(row);
        row.quantityMax = PF.subtraction(row.quantity, row.allotQuantity + row.freezeQuantity);
        this.form.toQuantity = row.quantityMax;
        this.form.row = row;
        IOT.getServerData('/serial/nos/list', 'get', { type: 1, inventoryId: row.inventoryId }, (ret) => {
          IOT.hideOverlay();
          if (ret.code === 200) {
            this.serialNo1 = ret.rows;
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
        this.dialogNewVisible = true;
      },
      showSelectCellModal() {
        if (!PF.isNull(this.form.row.wareId)) {
          IOT.tips('未选择仓库！', 'error');
          return false;
        }
        this.searchSelectCell.wareId = this.form.row.wareId;
        this.getCellData();
        this.getAreaData();
        this.innerVisibleCell = true;
      },
      resetForm(formName) {
        // 重置
        this.$refs[formName].resetFields();
      },
      handleClose(done) {
        this.resetForm('form');
        this.dialogNewVisible = false;
      },
      groupBox(data) {
        let that = this;
        IOT.showOverlay('保存中，请稍等...');
        IOT.getServerData(
          '/inventorys/groupBox',
          'get',
          { id: data.inventoryId, toQuantity: data.toQuantity, toBoxCode: data.toBoxCode },
          (ret) => {
            this.dialogGroupBox = false;
            IOT.hideOverlay();
            if (ret.code === 200) {
              IOT.tips('保存成功！', 'success', 1000, () => {
                that.getInventoryBalanceData();
              });
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
        this.resetForm(formName);
        this.dialogNewVisible = false;
      },
      commitForm(formName) {
        let that = this;
        IOT.showOverlay('保存中，请稍等...');
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let a = Math.ceil(that.form.toQuantity);

            if (that.form.serialNos.length <= 0 && this.serialNo1.length > 0) {
              this.$confirm('当前上架库存未录入序列号,会影响后续序列号追踪,是否继续？')
                .then((_) => {
                  this.commitUpperTask(formName);
                })
                .catch((_) => {
                  IOT.hideOverlay();
                });
            } else if (that.form.serialNos.length > 0 && that.serialNo1.length > 0 && that.form.serialNos.length < a) {
              this.$confirm('当前上架库存录入序列号与数量不一致,会影响后续序列号追踪,是否继续？')
                .then((_) => {
                  this.commitUpperTask(formName);
                })
                .catch((_) => {
                  IOT.hideOverlay();
                });
            } else {
              this.commitUpperTask(formName);
            }
          } else {
            IOT.hideOverlay();
            console.error('error submit!!');
            return false;
          }
        });
      },
      commitUpperTask(formName) {
        let that = this;
        let form = PF.JSON(this.form);
        for (let key in form.row) {
          form[key] = form.row[key];
        }
        // 主单位数量
        form.toQuantity = form.toQuantity * form.row.transRatio;
        if (form.toQuantity > form.row.quantityMax) {
          IOT.tips('输入数量不得大于可上架数量！', 'error', 1000, () => {
            IOT.hideOverlay();
          });
          return false;
        }
        delete form.row;
        form.fromBoxCode = form.boxCode;
        form.fromCellCode = form.cellCode;
        IOT.getServerData('/upper/tasks/upperTask', 'post', form, (ret) => {
          IOT.hideOverlay();
          if (ret.code === 200) {
            IOT.tips('保存成功！', 'success', 1000, () => {
              that.getInventoryBalanceData();
            });
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
        this.resetForm(formName);
        this.dialogNewVisible = false;
      },
      findUpperCell(row) {
        IOT.getServerData(
          '/upper/tasks/findUpperCell',
          'get',
          row,
          (ret) => {
            if (ret.code === 200) {
              this.isFindUpperCell = true;
              this.form.findUpperCell = ret.data;
              this.form.toCellCode = ret.data;
            } else if (ret.code === 122) {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'warning');
              this.isFindUpperCell = false;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          },
          true
        );
      },
      generateInCellTask() {
        IOT.showOverlay('生成中....');
        IOT.getServerData(
          '/upper/tasks/generateInCellTask',
          'post',
          this.multipleSelection,
          (ret) => {
            IOT.hideOverlay();
            if (ret.code === 200) {
              this.isFindUpperCell = true;
              this.form.findUpperCell = ret.data;
              this.form.toCellCode = ret.data;
            } else if (ret.code === 122) {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'warning');
              this.isFindUpperCell = false;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          },
          true
        );
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      selectCell(cellCode) {
        this.form.toCellCode = cellCode;
        this.innerVisibleCell = false;
      },
    },
    created() {
      this.getInventoryBalanceData();
      PF.getOrganizations((rows) => {
        this.organizations = rows;
      });
    },
    mounted() {},
  };
</script>

<style scoped lang="less">
  .toCellInventory {
  }
</style>
